import React from "react";
import "./createAutomations.css";
import '../../../pages/campaignList/masterTargetinngList/MasterTargetingList.css'
import PrimaryBtnRB from "../../../components/Buttons/primaryBtnRB.js/primaryBtnRB";
import SectionTitle from "../../../components/sectionTitle";
import MultiSelectWithFilters from "../../../components/DropDown/MultiSelectWithFilters";
import * as Yup from "yup";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import closeIcon from "../../../assets/icons/close_icon.png";
import calendarIcon from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import { useState, useRef, useReducer,useEffect,useMemo } from "react";
import { Stack } from "react-bootstrap";
import RadioButtonRb from "../../../components/Buttons/radioButtonRb/radioButtonRb";
import { useNavigate, useSearchParams } from "react-router-dom";
import { encodeData } from "../../../helper/common/encodeData";
import useUserDetails from "../../../helper/userData/useUserDetails";
import { masterApi } from "../../../apis/masterApi";
import Loader from "../../../components/loader";
import { useGlobalContext } from "../../../context/global";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../../constant/HardTypes";
import axios from "axios";
import { formatDateFn } from "../../../helper/common/formatDateForAPI";
import CalenderComponent from "../../../components/calender/Calender";
import MultipleDateCalendar from "../../../components/calender/MultipleDateCalendar";
import FilterChips from "../../../components/FilterChips/FilterChips";
import TickbarList from "../../../components/Tickbar/TickbarList";
import {
  initialRulesConfigState,
  rulesActions,
  staticRulesConfig,
  staticFiltersConfigs,
} from "../../../constant/Automations/rulesConfig";
import automationDataParser from "../../../helper/Automations/automationDataParser";
import capitalizeFirstLetterInArray from "../../../helper/common/capitalizeFirstLetterOfArray";
import { useLocation } from "react-router-dom";
import RuleContainer from "../../../components/Automations/RuleContainer/RuleContainer";
import convertDateFormatWithMoment from "../../../helper/common/convertToDate";
import convertAPIdataToStateConfigs from "../../../constant/Automations/convertAPIdataToStateConfigs";
import automationsDataChangesChecker from "../../../helper/Automations/automationsDataChangesChecker";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";


const CreateAutomation = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const {state} = useLocation();
  // Checking if it is coming from update page
  const forUpdate = state!==null
  const updateId = state?.id
  const initialSelectedCampaigns = state?.allCampaigns?.split(",") ?? [];
  const initialRuleObjective = state?.rule_objective
  const initialRuleName = state?.name ?? ""
  const updateRuleScheduleConfigs = state?.hasOwnProperty('schedule_config') ? state?.schedule_config : {};
  const {frequency_values,day_frequency,start_date,end_date} = updateRuleScheduleConfigs;
  const initialFrequencyValues = capitalizeFirstLetterInArray(frequency_values) ?? [];
  const initialStartDate = forUpdate ? convertDateFormatWithMoment(start_date):new Date();
  const initialEndDate = end_date ? convertDateFormatWithMoment(end_date) : null;
  const initialRuleTasks = forUpdate ? convertAPIdataToStateConfigs(state?.rule_tasks) : initialRulesConfigState
  const initialBidCaps = convertAPICapData(state?.rule_tasks ?? []);
  const queryData = serializeQuery(searchParams);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState(initialSelectedCampaigns);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [validationResult, setValidationResult] = useState({});
  const [rulesFrequencyValues, setRulesFrequencyValues] = useState(initialFrequencyValues??[]);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [ruleSetError, setRuleSetError] = useState(false);
  const [bidCaps,setBidCaps] = useState(initialBidCaps)
  const { showAlert } = useGlobalContext();
  const [userDetails] = useUserDetails();



 
  // Checker For Checking if queryData is there or Not?
  const isQuery = Object.keys(queryData)?.length ? true : false;
  const client_id = JSON.parse(localStorage.getItem("clientId"));

  // Variable For Data

  const { filterFields } = staticFiltersConfigs;
  const {
    ADD_RULE,
    ADD_FILTER,
    REMOVE_FILTER,
    UPDATE_FILTER_VALUE,
    UPDATE_RULE_VALUE,
    DELETE_RULE,
    TOGGLE_RULE,
    RESET_RULE,
  } = rulesActions;

  const navigate = useNavigate();

  const WEEK_DAYS_CONFIG = [
    "All",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // ValidationSchema
  const validationSchema = Yup.object().shape({
    campaign_ids: Yup.array().min(1, "Please choose at least one campaign"),
    rule_name: Yup.string().trim().required("Rule name is required").test('is-unique','Rule name must be unique' , function (value) {
      if(forUpdate && initialRuleName === ruleName) {
        return true
      } else {
        return !ruleTasksName?.includes(value?.toLowerCase().replace(/^\s+|\s+$/g, ''));
      }
     
    }).test('is-not-empty', 'Rule name must not be empty', function (value) {
      return !!value.trim(); // Check if the trimmed value is not empty
    }),
    rule_frequency: Yup.string().required(
      "Please choose rule frequency" 
    ),
  });

  const calendarRef = useRef(null);
  const calendarRefForEndDate = useRef(null)

  // Custom Data
  const optionsForDropDown = useMemo(() => {
    return allCampaigns?.map((campaign) => {
      let obj = {
        name:campaign?.name,
        status:campaign?.status
      }
      return obj
    }) ?? []
  },[allCampaigns])

  const campaignWithStatus = useMemo(() => {
    return optionsForDropDown?.reduce((acc,obj) => {
      const name = obj.name
      const status = obj.status
      acc[name] = status
      return acc
    },{}) 
  },[optionsForDropDown])


 

  const dataForChips = [
    {
      checkedValue: [...selectedCampaigns],
      fieldNameOfSelectedBtn: "Campaigns",
      field_name: "Campaigns",
    },
  ];

  // Initial Data with Update Or New Creation
  const ruleTypeData = isQuery ? queryData?.ruleFor : state?.hasOwnProperty('rule_objective') ? initialRuleObjective : "Improve Efficiency";

  const [ruleData, setRuleData] = useState({
    ruleType: ruleTypeData,
  });
  const [ruleFrequency, setRuleFrequency] = useState(day_frequency);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate,setEndDate] = useState(initialEndDate);
  const [ruleTasksName,setRuleTasksName] = useState([])
  const [ruleName, setRuleName] = useState(initialRuleName);
  const [loading, setLoading] = useState(false);

  const formattedDate = formatDateFn(startDate,true);
  const formattedEndDate = endDate === null ? null : formatDateFn(endDate,true)

  const dropDownRowsReducer = (state, action) => {
    const { action: sentAction, payload } = action;
    const caseFor = payload?.case;
    const mainIndex = payload?.ruleIndex;
    const childIndex = payload?.filterIndex;
    const filterKey = payload?.filterKey;
    const value = payload?.value;
    switch (sentAction) {
      case ADD_RULE:
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [
              ...state[caseFor]?.rules,
              {
                isDisabled: false,
                filters: [],
                rule_duration: "3 Days",
                ...(caseFor !== "pauseCase" && {
                  [caseFor === "increaseCase"
                    ? "increase_bid"
                    : "decrease_bid"]: 10,
                }),
              },
            ],
          },
        };
      case DELETE_RULE:
        const { sentIndex } = payload;
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.filter(
              (rule, index) => sentIndex !== index
            ),
          },
        };
      case TOGGLE_RULE:
        const { recievedIndex } = payload;
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              return index === recievedIndex
                ? { ...rule, isDisabled: !rule?.isDisabled }
                : { ...rule };
            }),
          },
        };
      case ADD_FILTER:
        const { parentIndex } = payload;
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              if (index === parentIndex) {
                rule.filters = [...rule.filters, { ...filterFields }];
              }
              return rule;
            }),
          },
        };
      case REMOVE_FILTER:
        const { filterIndex, defaultIndex } = payload;
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              if (index === defaultIndex) {
                rule.filters = [...rule.filters].filter(
                  (filter, ruleFilterIndex) => ruleFilterIndex !== filterIndex
                );
              }
              return rule;
            }),
          },
        };
      case UPDATE_FILTER_VALUE:
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              if (index === mainIndex) {
                rule = {
                  ...rule,
                  filters: rule?.filters?.map((filter, ruleIndex) => {
                    if (ruleIndex === childIndex) {
                      if(filterKey === "value" && !isNaN(value)) {
                        filter[filterKey] = value;
                      } else if(filterKey!=="value") {
                        filter[filterKey] = value
                      }
                    }
                    return filter;
                  }),
                };
              }
              return rule;
            }),
          },
        };
      case UPDATE_RULE_VALUE:
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              if (index === mainIndex && !isNaN(value) && filterKey!=="rule_duration") {
                rule[filterKey] = value
              } else if(filterKey==="rule_duration") {
                rule[filterKey] = value
              }
              return rule;
            }),
          },
        };
      case RESET_RULE:
        return {
          ...state,
          [caseFor]: {
            ...state[caseFor],
            rules: [...state[caseFor]?.rules]?.map((rule, index) => {
              if (index === mainIndex) {
                rule["filters"] = [{ ...filterFields }];
              }
              return rule;
            }),
          },
        };
      default:
        return state;
    }
  };

  const [dropDownRowsState, dispatchDropDownRowsActions] = useReducer(
    dropDownRowsReducer,
    JSON.parse(JSON.stringify(initialRuleTasks))
  );



  // Effects

  useEffect(() => {
    getCampaignList();
    getRulesNames()
  }, []);

  

  // Methods

  // API Calls Here
  async function getCampaignList() {
    try {
      const data = {
        source: "amazon",
        limit: 5000,
      };
      setLoading(true);
      const result = await masterApi(data, "campaignList", false);
      if (result?.statusCode !== 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Some Unknown Error Occured While Getting Campaigns",
        });
      }
      if (result?.data?.data) {
        const filteredData = result?.data?.data?.filter((data) => {
         return (data?.status !== "ARCHIVED" && data?.hasOwnProperty("platform_id") && data?.platform_id!==null)
        }) ?? []
        setAllCampaigns(filteredData);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function publishRule(dataToPost) {
    try {
      setIsLoaderVisible(true);
      const url = `${process.env.REACT_APP_ADS_API}/client/${client_id}/automation-rule-set?source=amazon`;
      const request = await axios.post(url, dataToPost);
      if (request?.status !== 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Some Unknown Error Occured while creating Ruleset",
        });
        setIsLoaderVisible(false);
      } else {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_SUCCESS,
          title: "Success",
          description: "Ruleset created successfully",
        });
        setIsLoaderVisible(false);
        navigate('/automations')
      }
    } catch (error) {
      showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        backgroundColor: TOAST_DANGER,
        title: "Error",
        description: "Some Unknown Error Occured while creating Ruleset",
      });
      setIsLoaderVisible(false);
    }
  }

  async function getRulesNames () {
    try {
      const data = {
        source:"amazon",
        fields:"name"
      }
      const result = await masterApi(data,'automationsRule',false)
      if(result?.data) {
        setRuleTasksName(result?.data?.map((data) => data?.name?.toLowerCase()))
      }
      if(result?.statusCode!==200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Unknown error occurred while fetching rule names.",
        });
      }
    } catch (error) {
      showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        backgroundColor: TOAST_DANGER,
        title: "Error",
        description: "Unknown error occurred while fetching rule names.",
      });
    }
  }

  async function handleStatusUpdate(data) {
    const dataToUpdate = [
      {
        id: updateId,
        ...data
      },
    ];
    setIsLoaderVisible(true);
    try {
      const result = await masterApi(
        dataToUpdate,
        "automationsRule",
        false,
        "patch"
      );
      if (result?.statusCode === 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_SUCCESS,
          title: "Success",
          description: "Updated Successfully!",
        });
        navigate('/automations');
      }
      if (result?.statusCode !== 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Some unknown error occurred while updating!",
        });
      }
      setIsLoaderVisible(false);
    } catch (error) {
      console.log(error);
    }
  }

  // Campaign List Dropdown Function

  function removeError(key) {
    setValidationResult((prev) => {
      const copy = { ...prev };
      if (key in copy) {
        delete copy[key];
      }
      return copy;
    });
  }

  const getSelectedCampaigns = (data) => {
      setSelectedCampaigns([...data]);
      removeError("campaign_ids");
    }
  

  // Methods For Tabs

  function serializeQuery(queryUrl) {
    return Object.fromEntries(queryUrl);
  }

  const onCancelClickRemoveFilteringHandlerr = () => {
    setSelectedCampaigns([]);
  };

  function convertAPICapData(mainFilters) {
    const intitialCapData = {
      decrease: {
        disabled: true,
        value: "",
      },
      increase: {
        disabled: true,
        value: "",
      },
    };
    for (let i = 0; i < mainFilters?.length; i++) {
      if (
        mainFilters[
          i
        ]?.rule_action_config?.entity_action_config?.action_type?.includes(
          "INCREASE"
        ) &&
        mainFilters[
          i
        ]?.rule_action_config?.entity_action_config?.hasOwnProperty("threshold")
      ) {
        intitialCapData.increase.value =
          mainFilters[i]?.rule_action_config?.entity_action_config?.threshold;
      }
      if (
        mainFilters[
          i
        ]?.rule_action_config?.entity_action_config?.action_type?.includes(
          "DECREASE"
        ) &&
        mainFilters[
          i
        ]?.rule_action_config?.entity_action_config?.hasOwnProperty("threshold")
      ) {
        intitialCapData.decrease.value =
          mainFilters[i]?.rule_action_config?.entity_action_config?.threshold;
      }
    }
    return intitialCapData;
  }

  useEffect(() => {
   const mainData = automationDataParser(dropDownRowsState,ruleName);
  //  if(mainData?.length) {
    const allFilters = mainData?.map((data) =>
      data?.rule_action_config?.entity_action_config?.action_type?.toLowerCase()
    );
    const isIncrease = allFilters?.includes("increase_bid");
    const inDecrease = allFilters?.includes("decrease_bid");
    setBidCaps((prev) => {
      prev.increase.disabled = isIncrease;
      prev.decrease.disabled = inDecrease;
      return { ...prev };
    });
  //  }
  },[dropDownRowsState])

  const handleBidCapChange = (type, value) => {
    const parsedData = JSON.parse(JSON.stringify(bidCaps));
    parsedData[type].value = value
    setBidCaps(parsedData);
  };

  // Method to POST Data For Rule creation
  async function handleSubmitRule() {
    const allCampaignIds = allCampaigns?.reduce((acc, curr) => {
      if (selectedCampaigns.includes(curr.name)) {
        acc.push(curr.id);
      }
      return acc;
    }, []);
    const frequency_values = ruleFrequency?.includes("MONTH_DAYS")
      ? rulesFrequencyValues?.map((data) => data?.day)
      : rulesFrequencyValues?.filter((data) => data !== "All");
    let dataToPost = {
      source: "amazon",
      client_id:client_id,
      rule_objective: ruleData?.ruleType,
      name: ruleName,
      modified_by: userDetails?.user?.email,
      status: "Active",
      schedule_config: {
        type: "TargetingSchedule",
        start_date: formattedDate,
        // If End Date is selected and it is not null then only pass it in Payload
        ...((endDate!==null && startDate<endDate) && {
          end_date:formattedEndDate
        }),
        day_frequency: ruleFrequency==="EVERYDAY"?"WEEK_DAYS":ruleFrequency,
        frequency_values: ruleFrequency ==="EVERYDAY" ? WEEK_DAYS_CONFIG?.filter(val => val!=="All")?.map((data) => data?.toLowerCase()) : ruleFrequency === "WEEK_DAYS"  ? [...frequency_values]?.map((data) => data?.toLowerCase())  : [...frequency_values]
      },
      rule_tasks: [],
      entity_filter: {
        version: "targeting_V1",
        entity_filter_rule: {
          filters: [
            {
              field_name: "Campaign_Id",
              operator: "IN",
              value: allCampaignIds,
            },
          ],
        },
      },
    };

    const ruleTasks = automationDataParser(dropDownRowsState,ruleName,bidCaps);
    dataToPost["rule_tasks"] = ruleTasks;
    try {
      const dataToBeValidated = {
        campaign_ids: allCampaignIds,
        rule_name: ruleName,
        rule_frequency: ruleFrequency,
      };
      const { valid, errors } = await validateData(dataToBeValidated);
      let validation = true;

      if (!valid) {
        validation = false;
        setValidationResult(errors);
      }

      if (dataToPost?.rule_tasks?.length === 0) {
        validation = false;
        setRuleSetError(true);
      } 

      if(dataToPost?.rule_tasks?.length >= 1) {
        setRuleSetError(false)
      }

      if (
        rulesFrequencyValues?.length === 0 &&
        ruleFrequency !== "EVERYDAY" &&
        ruleFrequency !== ""
      ) {
        validation = false;
        showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Please Add Frequency Values",
        });
      }

      if (validation) {
        const dataForUpdate = forUpdate ? automationsDataChangesChecker(state,dataToPost) : {}
        const updatedData = forUpdate ? await handleStatusUpdate(dataForUpdate) : await publishRule(dataToPost)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function validateData(data) {
    let errorsObject = { valid: true, errors: {} };
    try {
      validationSchema.validateSync(data, { abortEarly: false });
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      errorsObject.valid = false;
      errorsObject.errors = errors;
    }
    return errorsObject;
  }

  // Methods For Frequency Change

  const handleFrequencyChange = (e) => {
    setRuleFrequency(e.target.id);
    setRulesFrequencyValues([]);
    removeError("rule_frequency");
  };

  // Method For Rule Name Change

  const handleRuleNameChange = (e) => {
    setRuleName(e.target.value);
    removeError("rule_name");
  };

  const onWeekDaysChange = (data) => {
    setRulesFrequencyValues(data);
  };

  const onStartDaysChange = (item,dateType) => {
    if(dateType === "start") {
      setStartDate(item);
    } else {
      setEndDate(item)
    }
    setShowCustomDateBox(false);
  };

  const dateInputClickHandler = (calendarFor) => {
    setShowCustomDateBox(calendarFor);
  };

  const filterLogic = (value,data) => {
    return data?.filter((campaign) => {
      const isFound = optionsForDropDown.find((data) => data?.name === campaign && (value?.toUpperCase() === data?.status || value === "All")) 
      return isFound
    })
  }

  return (
    <>
      <Loader visible={isLoaderVisible} />
      <div className="main-content-container-oncolapse-mode">
        <div className="d-flex justify-content-between align-items-center w-100">
          <SectionTitle sectionTitleHeading={ forUpdate ? "Update Existing Rule" : "Create New Rule"} />
        </div>
        <div className="mt-3">
          <div className="choose-campaign-container">
            <p className="pageTitleText mb-3">Rule Creation For</p>
            <div className="card-style-new">
              <div className="row">
                  <div className="col-lg-6">
                <MultiSelectWithFilters
                  title={"Choose Campaign *"}
                  classes={"multiselect-search-box"}
                  onApply={getSelectedCampaigns}
                  options={optionsForDropDown?.map((data) => data?.name) ?? []}
                  optionChange={true}
                  filtersLogic={filterLogic}
                  filters={["All","Enabled","Paused"]}
                  renderProp={(recievedProp) => {
                    const campaignStatus = campaignWithStatus[recievedProp]
                    return (
                      <div className="mt-status-dot-container">
                         <div className={`${campaignStatus==="PAUSED"?'paused':'enabled'}-status-dots-mt`}>
                      </div>
                      </div>
                   
                    )
                  }}
                  defaultSelected={selectedCampaigns}
                  isSearch={true}
                  isLoading={loading} 
                  placeHolder={"Search For Campaigns"}
                />
                <p className="text-danger">
                  {validationResult["campaign_ids"] &&
                    validationResult["campaign_ids"]}
                </p>
              
              </div>
              <div className="col-lg-6">
                <CustomDropDown
                  title={"Choose Objective"}
                  data={isQuery ? [queryData?.ruleFor] : ["Improve Efficiency"]}
                  targettingValue={ruleData?.ruleType}
                  type={"campaign_type"}
                  src={dropdownIcon}
                />
              </div>
              </div>
              {selectedCampaigns?.length ? (
                  <FilterChips
                    filterChipList={dataForChips}
                    onCancelClickRemoveFilteringHandler={
                      onCancelClickRemoveFilteringHandlerr
                    }
                    isResetButtonNeeded={false}
                  />
                ) : null}
            </div>
          </div>
          <hr />
          <div className="mt-2">
            <p className="pageTitleText mb-3">Set Rules</p>
            <p className="automation-rules-desc mb-3">
              Set Automate rules to optimize your campaigns by adding different
              type of rules.{" "}
            </p>
            <p className="mb-3">
              <span className="text-danger">*</span>
              <span className="automation-rules-desc">
                Minimum 1 rule needed, you can set up to 5 rules- 2 Increase
                case, 2 Decrease case and 1 Pause case
              </span>{" "}
            </p>
           { ruleSetError ? <p className="text-danger">*Please create at least 1 relevant rule set</p> : null}
            <div className="row">
              {Object.entries(dropDownRowsState)?.map((data) => {
                const currentCase = data[0];
                const caseHeading = staticRulesConfig[currentCase]?.caseHeading;
                const caseHeaderImage =
                  staticRulesConfig[currentCase]?.caseImage;
                const hideIncreaseByField = currentCase === "pauseCase";
                const { rules, maxRules } = data[1];
                const rulesContainerPropsData = {
                  currentCase,
                  caseHeading,
                  caseHeaderImage,
                  hideIncreaseByField,
                  rules,
                  maxRules,
                  handleRulesActions: dispatchDropDownRowsActions,
                };
                return (
                  <div className="col-lg-4 col-12" key={currentCase}>
                    <div className="h-100 box-blank-space">
                      <RuleContainer {...rulesContainerPropsData} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-2">
            <p className="pageTitleText mb-3">Rule Specific Details</p>
            <div className="card-style-new">
              <div className="row">
                <div className="col-lg-6 col-12">
                <Stack>
                <label className="mb-1 schedule-config-label">
                  Set Rule Name <span className="text-danger">*</span>
                </label>
                <div class="input-group input-group-lg w-100">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Rule Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={handleRuleNameChange}
                    value={ruleName}
                  />
                </div>
                <div>
                  <p className="text-danger">
                    {validationResult["rule_name"] &&
                      validationResult["rule_name"]}
                  </p>
                </div>
                </Stack>
                </div>
                <div className="col-lg-2 col-12">
                <Stack className={forUpdate ?"disable-pointer-events":""}>
                <label htmlFor="StartDate" className="mb-1 schedule-config-label"> Start Date</label>
                <>
                  <div className="row position-relative">
                    <div className="col-lg-4">
                      <button
                        className="date-container date-btn-campaignlist date-btn-height-automations"
                        onClick={() => dateInputClickHandler("start")}
                      >
                        <div className="d-flex justify-conntent-center">
                          <span className="date-btn-text">{formattedDate}</span>
                        </div>
                        <div className="btn-calender-img-holder">
                          <span className="">
                            <img
                              className="dropdown-img-for-select"
                              src={selectSideIcon}
                              alt={"calendar"}
                            ></img>

                            <img
                              className="calender-icon-for-campaignlist"
                              src={calendarIcon}
                              alt={"calendar"}
                            ></img>
                          </span>
                        </div>
                      </button>
                      <div
                        ref={calendarRef}
                        className={
                          showCustomDateBox==="start"
                            ? "calender-inclasslist-container"
                            : "hide"
                        }
                      >
                        <span className="calender-close-icon-campaignlist">
                          <img
                            onClick={() => setShowCustomDateBox(false)}
                            src={closeIcon}
                            alt={"close"}
                          ></img>
                        </span>
                      </div>
                    </div>
                    {showCustomDateBox==="start" && (
                    <div className="automations-calendar">
                        <CalenderComponent
                      singleDatePicker={true}
                      maxDays={30}
                      date={startDate}
                      setShowCustomDateBox={setShowCustomDateBox}
                      onSingleDateChange={(item) => onStartDaysChange(item,"start")}
                    />
                    </div>
                  
                  )}
                  </div>
                 
                </>
                </Stack>
                </div>
                <div className="col-lg-1 col-12">
                <Stack className="">
                <label htmlFor="endDate" className="mb-1 schedule-config-label"> End Date</label>
                <>
                  <div className="row position-relative">
                    <div className="col-lg-4">
                      <button
                        className="date-container date-btn-campaignlist date-btn-height-automations"
                        onClick={() => dateInputClickHandler("end")}
                      >
                        <div className="d-flex justify-conntent-center">
                          <span className="date-btn-text">{formattedEndDate===null ? "Please Select" : formattedEndDate}</span>
                        </div>
                        <div className="btn-calender-img-holder">
                          <span className="">
                            <img
                              className="dropdown-img-for-select"
                              src={selectSideIcon}
                              alt={"calendar"}
                            ></img>

                            <img
                              className="calender-icon-for-campaignlist"
                              src={calendarIcon}
                              alt={"calendar"}
                            ></img>
                          </span>
                        </div>
                      </button>
                      <div
                        ref={calendarRefForEndDate}
                        className={
                          showCustomDateBox==="end"
                            ? "calender-inclasslist-container "
                            : "hide"
                        }
                      >
                        <span className="calender-close-icon-campaignlist">
                          <img
                            onClick={() => setShowCustomDateBox(false)}
                            src={closeIcon}
                            alt={"close"}
                          ></img>
                        </span>
                      </div>
                    </div>
                    {showCustomDateBox==="end" && (
                    <div className="automations-calendar">
                        <CalenderComponent
                      singleDatePicker={true}
                      maxDays={180}
                      date={endDate}
                      setShowCustomDateBox={setShowCustomDateBox}
                      onSingleDateChange={(item) => onStartDaysChange(item,"end")}
                    />
                    </div>
                  
                  )}
                  </div>
                 
                </>
                </Stack>
                </div>
              </div>
              <Stack className="mb-2">
              <ReactTooltip id="my-tooltip" style={{zIndex:"999999", fontSize:"12px", padding:"8px"}} effect="solid" place="top" variant="dark" />
              <div className="col-1 d-flex align-items-center gap-2 bid-cap-heading">
                <label className="text-nowrap">
                 <span> Set Bid Cap</span>:
                </label>
                <div data-tooltip-content="Set threshold values for bid automation rules - Upper cap for increase rule and lower cap for decrease rule" data-tooltip-id="my-tooltip"><FaInfoCircle height={20} className="cursor-pointer-global"/></div>
                </div>
              <div className="row">         
                <div className="col-1">
                  <small>Upper Cap</small>
                  <div class="input-group input-group-lg w-100">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter"
                    className="form-control bid-cap-input"
                    aria-label="Username"
                    disabled={!bidCaps?.increase?.disabled}
                    onChange={(e) => {
                      if(!isNaN(e.target.value?.trim())) {
                        handleBidCapChange("increase",e.target.value?.trim())
                      }
                    }}
                    value={bidCaps?.increase?.value}
                    aria-describedby="basic-addon1"
                  />
                </div>
                </div>
                <div className="col-1">
                <small>Lower Cap</small>
                <div class="input-group input-group-lg w-100">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter"
                    aria-label="Username"
                    className="form-control bid-cap-input"
                    disabled={!bidCaps?.decrease?.disabled}
                    onChange={(e) => {
                      if(!isNaN(e.target.value?.trim())) {
                        handleBidCapChange("decrease",e.target.value?.trim())
                      }
                    }}
                    value={bidCaps?.decrease?.value}
                    aria-describedby="basic-addon1"
                  />
                </div>
                </div>
              </div>
              </Stack>
            
              <Stack className="mt-4">
                <label className="schedule-config-label mb-1">
                  Schedule For <span className="text-danger">*</span>{" "}
                  {validationResult["rule_frequency"] && (
                    <span className="text-danger">
                      {validationResult["rule_frequency"]}
                    </span>
                  )}
                </label>
                <RadioButtonRb
                  onChange={handleFrequencyChange}
                  id={"EVERYDAY"}
                  checked={ruleFrequency === "EVERYDAY"}
                  name={"group1"}
                  label={"Run Every Day"}
                />
                {/* <div className="d-flex"> */}
                <RadioButtonRb
                  name={"group1"}
                  id={"WEEK_DAYS"}
                  checked={ruleFrequency === "WEEK_DAYS"}
                  onChange={handleFrequencyChange}
                  label={"Run on Week Days"}
                />
                { (
                  <div className={ ruleFrequency!=="WEEK_DAYS" ? "mt-1 mx-4 switch-btn-disabled-smart-ads" : "mt-1 mx-4"}>
                    <TickbarList
                      options={WEEK_DAYS_CONFIG}
                      selectedOptions={rulesFrequencyValues}
                      getUpdatedData={onWeekDaysChange}
                    />
                  </div>
                ) }
                {/* </div> */}
                {/* // Hiding on Temprorary basis */}
                {/* <div className="d-flex">
                  <RadioButtonRb
                    name={"group1"}
                    id={"MONTH_DAYS"}
                    checked={ruleFrequency === "MONTH_DAYS"}
                    onChange={handleFrequencyChange}
                    label={"Run on Month Days"}
                  />
                  {ruleFrequency === "MONTH_DAYS" ? (
                    <MultipleDateCalendar
                      dateValues={rulesFrequencyValues}
                      handleDateChange={setRulesFrequencyValues}
                      multiSelection={true}
                    />
                  ) : null}
                </div> */} 
              </Stack>
            </div>
          </div>
          <div className="mt-2 d-flex gap-3 justify-content-end">
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
            <PrimaryBtnRB
              insideBtnText={forUpdate ? "Update Rule" :"Publish Rule"}
              pageClickOptionsHandler={handleSubmitRule}
              id={forUpdate?'update-automation-rule':'publish-automation-rule'}
              disabled={isLoaderVisible}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAutomation;
