import React, { useRef } from "react";
import { Button, Stack } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dragIcon from "../../assets/svgs/filter-bars-black.svg";
import overviewSeiingIcon from "../../assets/svgs/filter.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useState } from "react";
import "./dynamicFilterModal.css";
import { useEffect } from "react";
import {Tooltip as ReactTooltip} from 'react-tooltip';
const DynamicFilterModal = ({
  columns,
  percentageColumnRequired,
  percentageColumns,
  getAppliedFilters,
  cancelFilterFor,
  column1Heading,
  column2Heading,
  buttonHeading,
  isDynamicIcon,
  dynamicIcon,
  saveFilter,
  alignment,
  buttonClassNames,
  isDisabled,
  id,
  tooltipId,
  tooltipContent,
  mainDesc
}) => {
  const [filters, setFilters] = useState([...columns]);
  const [percentageFilters, setPercentageFilters] = useState(
    percentageColumnRequired ? [...percentageColumns] : []
  );
  const [savedFilters, setSavedFilters] = useState({savedPercentageFilter:percentageColumnRequired ? [...percentageColumns] : [],savedNormalFilters:[...columns]});
  const [isVisible, setIsVisible] = useState(false);
    const [filterError,setFilterError] = useState({isError:false,fieldName:"",errorKey:""})
  const ref = useDetectClickOutside({ onTriggered: closeFilters });
  const buttonIcon = isDynamicIcon ? dynamicIcon : overviewSeiingIcon
  const isButtonDisabled = filters.some((data) => data?.isError) || percentageFilters.some((data) => data?.isError)


    function inputValueChange (value,type,metric) {
        setFilters((prev) => {
            const copy = [...prev]?.map((data) => {
              return {...data}
            })
            let updatedCopy = copy.map((data) => {
              data = {...data}
                if(data.field_name === metric) {
                    data[type] = value
                    const isError = minMaxChecker(data["minValue"],data["maxValue"],metric)
                    data["isError"] = isError
                }
                return data
            })
            return updatedCopy
        })
    }
    
    function inputValueChangeForPercentageMetric (value,type,metric) {
        setPercentageFilters((prev) => {
            const copy = [...prev]?.map((data) => {
              return {...data}
            })
            let updatedCopy = copy.map((data) => {
                if(data.field_name === metric) {
                  data[type] = value
                 const isError = minMaxChecker(data["minValue"],data["maxValue"],metric)
                 data["isError"] = isError
                }
                return data
            })
            return updatedCopy
        })
    }

    const minMaxChecker = (minValue,maxValue,fieldName) => {
      const maxVal = (maxValue === "-"  || maxValue === "")? 0 : Number(maxValue)
      const minVal = ( minValue === "-" || minValue === "") ? 0 : Number(minValue)
      const condition =  (minVal===0 && maxVal<0)?false:(maxVal<minVal && (maxValue!=="" && maxValue!=="-"))
      setFilterError((prev) => {
        return {
          ...prev,
          isError:condition,
          fieldName:fieldName
        }
      })
      return condition
    }
  
    function overviewSettingBtnHandler () {
        setIsVisible(!isVisible)
    }
  
    function resetFilter () {
        setFilters((prev) => {
          return [...prev]?.map((data) => {
            if(!data?.disabled) {
              data.minValue = "";
              data.maxValue = "";
            }
            data.isError = false
            return data
          })
        });
        setFilterError((prev) => {
          return {
            ...prev,
            isError:false
          }
        })
        if(percentageColumnRequired) {
            setPercentageFilters((prev) => {
              return [...prev]?.map((data) => {
                if(!data?.disabled) {
                  data.minValue = "";
                  data.maxValue = "";
                }
                data.isError = false
                return data
              })
            })
        }
    }

  
    function applyFilter () {
      const appliedFilters = {
        percentageFilters,
        normalFilters:filters
      }
      getAppliedFilters(appliedFilters)
      if(saveFilter) {
        setSavedFilters((prev) => {
          return {
            ...prev,
            savedNormalFilters: structuredClone(filters),
            savedPercentageFilter: structuredClone(percentageFilters),
          };
        });
      }
     
      setIsVisible(!isVisible)
    }

  function closeFilters() {
    setIsVisible(false);
    if(saveFilter) {
      const { savedPercentageFilter, savedNormalFilters } = savedFilters;
      const savedPercentageFiltersNewData = structuredClone(
        savedPercentageFilter
      );
      const savedNormalFiltersNewData = structuredClone(savedNormalFilters);
      setPercentageFilters(JSON.parse(JSON.stringify([...savedPercentageFiltersNewData])));
      setFilters(JSON.parse(JSON.stringify([...savedNormalFiltersNewData])));
    }
  }

  useEffect(() => {
    if (cancelFilterFor !== "") {
      const currentStateUpdater = cancelFilterFor?.includes("comp")
        ? setPercentageFilters
        : setFilters;
      if (cancelFilterFor !== "reset") {
        currentStateUpdater((prev) => {
          return [...prev]?.map((data) => {
            if (data.field_key === cancelFilterFor) {
              data.minValue = "-";
              data.maxValue = "-";
            }
            return data;
          });
        });
      } else {
          if(percentageColumnRequired) {
          setPercentageFilters([...percentageColumns]);
         }
      setFilters([...columns]);
    }
  }}, [cancelFilterFor]);

  useEffect(() => {
    if(saveFilter) {
      setFilters([...columns])
      if(percentageColumnRequired) {
        setPercentageFilters([...percentageColumns])
      }
      setSavedFilters((prev) => {
        return {
          ...prev,
          savedNormalFilters:[...columns],
          savedPercentageFilter:percentageColumnRequired ? [...percentageColumns] : []
        }
      })
    }
  },[columns,percentageColumns])

  const tableFilterBody = (
    <>
      <div className="campaign-list-table-data">
        <h6 className="text-center">{column1Heading} </h6>
        {/* {isButtonDisabled ? <small className='position-absolute error-in-comp-filter text-danger'>Max should be {'>'} Min Value</small>:null} */}
        <Stack className="campaign-list-main-table">
          <div className={" reports-filter-table-wrapper filter"}>
            {columns?.length ? (
              <table
                className={
                  "table table reports-filter-table table-sm table-responsive"
                }
              >
                <thead className="fixed-table">
                  <tr className="text-center">
                    <th className={""}></th>
                    <th className={"hide"}>Action</th>
                    <th>Metric</th>
                    <th>Min</th>
                    <th>Max</th>
                  </tr>
                  <tr></tr>
                </thead>
                <DragDropContext>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <tbody
                        className="another-tbody list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ overflowY: "auto" }}
                      >
                        {filters?.map((key, index) => (
                          <Draggable
                            key={key.field_name}
                            draggableId={key.field_name}
                            index={index}
                            isDragDisabled={true}
                          >
                            {(provided) => (
                              <tr
                                className="cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <td className={"hide"}>
                                  <img
                                    src={dragIcon}
                                    className="filterIcon"
                                    alt="filter"
                                  />
                                </td>
                                <td className={""}></td>
                                <td>{key.field_name}</td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="d-flex justify-center actionn-text">
                                      <input
                                        className={(key?.isError)?"form-control-sm error-border-red": "form-control-sm"}
                                        type="text"
                                        // inputMode="number"
                                        disabled={key?.disabled}
                                        value={key?.minValue}
                                        onChange={(e) =>
                                          inputValueChange(
                                            e.target.value,
                                            "minValue",
                                            key.field_name
                                          )
                                        }
                                        id={key.field_name + "minValue"}
                                      ></input>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex gap-1">
                                    <div className="d-flex justify-center actionn-text">
                                      <input
                                        className="form-control-sm"
                                        type="text"
                                        // inputMode="number"
                                        disabled={key?.disabled}
                                        value={key?.maxValue}
                                        onChange={(e) =>
                                          inputValueChange(
                                            e.target.value,
                                            "maxValue",
                                            key.field_name
                                          )
                                        }
                                        id={key.field_name + "maxValue"}
                                      ></input>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            ) : (
              <div></div>
            )}
          </div>
        </Stack>
      </div>
    </>
  );

  const tableFilterByPercentage = (
    <>
      <div className="campaign-list-table-data position-relative">
        <h6 className="text-center">{column2Heading} </h6>
           {/* {  isButtonDisabled ? <small className='position-absolute error-in-comp-filter text-danger'>Max should be {'>'} Min Value</small>:null} */}
        <Stack className="campaign-list-main-table">
          <div className={" reports-filter-table-wrapper filter "}>
                
            {percentageColumns?.length ? (
              <table
                className={
                  "table table reports-filter-table table-sm table-responsive"
                }
              >
                <thead className="fixed-table">
                  <tr className="text-center">
                    <th className={""}></th>
                    <th className={"hide"}>Action</th>
                    <th>Metric</th>
                    <th>Min</th>
                    <th>Max</th>
                  </tr>
                  <tr></tr>
                </thead>
                <DragDropContext>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <tbody
                        className="another-tbody list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ overflowY: "auto" }}
                      >
                        {percentageFilters?.map((key, index) => (
                          <Draggable
                            key={key.field_name}
                            draggableId={key.field_name}
                            index={index}
                            isDragDisabled={true}
                          >
                            {(provided) => (
                              <tr
                                className="cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <td className={"hide"}>
                                  <img
                                    src={dragIcon}
                                    className="filterIcon"
                                    alt="filter"
                                  />
                                </td>
                                <td className={""}></td>
                                <td>{key.field_name}</td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div className="d-flex justify-center border actionn-text">
                                      <input
                                        className={ (key?.isError)?"form-control-sm error-border-red": "form-control-sm"}
                                        type="text"
                                        // inputMode="number"
                                        disabled={key?.disabled}
                                        value={key?.minValue}
                                        onChange={(e) =>
                                          inputValueChangeForPercentageMetric(
                                            e.target.value,
                                            "minValue",
                                            key.field_name
                                          )
                                        }
                                        id={key.field_name + "minValue"}
                                      ></input>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex gap-1">
                                    <div className="d-flex justify-center actionn-text">
                                      <input
                                        className="form-control-sm"
                                        type="text"
                                        // inputMode="number"
                                        disabled={key?.disabled}
                                        value={key?.maxValue}
                                        onChange={(e) =>
                                          inputValueChangeForPercentageMetric(
                                            e.target.value,
                                            "maxValue",
                                            key.field_name
                                          )
                                        }
                                        id={key.field_name + "maxValue"}
                                      ></input>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            ) : (
              <div></div>
            )}
          </div>
        </Stack>
      </div>
    </>
  );
  return (
    <>

        <ReactTooltip
          id={tooltipId}
          style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
          effect="solid"
          place="top"
          variant="dark"
        />

      <div
        className={`position-relative  ${
          isDisabled ? "disable-modal-on-active" : ""
        }`}
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
        ref={ref}
      >
        <button
          className={`primary-inactive-btn apply-filter d-flex align-items-center justify-content-end ${
            buttonClassNames ? buttonClassNames : ""
          }`}
          id={id ? id : ""}
          onClick={overviewSettingBtnHandler}
        >
          <div className="d-flex gap-2 align-items-center">
            {isDynamicIcon ? (
              dynamicIcon
            ) : (
              <img
                src={buttonIcon}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>
            )}
            {buttonHeading && <div>{buttonHeading}</div>}
          </div>
        </button>
        {isVisible && (
          <div
            className={`dynamic-filter-modal-container ${
              alignment === "left" ? "left-align" : "right-align"
            }`}
          >
            <div className="hide"></div>
            <div className="shadow-lg p-1 mb-5 rounded tooltip-content">
              <div className="d-flex justify-content-between align-items-center dynamic-filter-modal-btn-container px-3">
                <div className="text-left">{mainDesc ? mainDesc : "Filter Logic"}</div>
                <div
                  className={
                    percentageColumnRequired
                      ? "d-flex gap-2 align-items-center"
                      : "d-flex gap-2 align-items-center ml-10"
                  }
                >
                  <button
                    size="sm"
                    className="primary-inactive-btn"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                  <Button
                    size="sm"
                    className="primary-active-btn"
                    onClick={applyFilter}
                    id={id ? "apply-" + id : "apply-range-filters"}
                    disabled={isButtonDisabled}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              {<div className={`${isButtonDisabled ? "text-center d-block" : "invisible"
            }`}><small className='text-center text-danger'>Max should be {'>'} Min Value*</small></div>}
              <div className={percentageColumnRequired ? "d-flex gap-2" : ""}>
                <div className="bg-white py-2 rounded">{tableFilterBody}</div>
                {percentageColumnRequired && (
                  <div className="bg-white py-2 rounded">
                    {tableFilterByPercentage}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicFilterModal;
